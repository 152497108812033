.MuiContainer-root {
  padding: 0;
  margin: 0;
  max-width: none;
}
@media screen and (min-width: 1280px) {
.MuiContainer-maxWidthLg {
    max-width: none !important;
}
}

.MuiTypography-root span {
  color: #000
}

/* Estilos de la camara */
#container-circles {
  bottom: 25%;
}

#inner-circle {
  background: #1de9b6;
}

/* Estilos del Welcome */
.group {
 position: relative;
 width: 100%;
 border-bottom: 1px solid #fff;
}

.input {
 font-size: 1rem;
 font-weight: 700;
 padding: 10px 10px 10px 5px;
 display: block;
 /* width: 200px; */
 border: none;
 /* border-bottom: 1px solid #fff; */
 background: transparent;
 width: 75%;
 color:#fff;
}

.input:focus {
 outline: none;
}

.input-label {
 color:#fff;
 font-size: 1rem;
 font-weight: normal;
 position: absolute;
 pointer-events: cursor;
 left: 5px;
 top: 10px;
 transition: 0.2s ease all;
 -moz-transition: 0.2s ease all;
 -webkit-transition: 0.2s ease all;
}

.input:focus ~ label, .input:valid ~ label {
 top: -20px;
 font-size: 0.75rem;
 color: #fff;
}

.bar {
 position: relative;
 display: block;
 width: 100%;
}

.bar:before, .bar:after {
 content: '';
 height: 2px;
 width: 0;
 bottom: 1px;
 position: absolute;
 background: #1de9b6;
 transition: 0.2s ease all;
 -moz-transition: 0.2s ease all;
 -webkit-transition: 0.2s ease all;
}

.bar:before {
 left: 50%;
}

.bar:after {
 right: 50%;
}

.input:focus ~ .bar:before, .input:focus ~ .bar:after {
 width: 50%;
}

.highlight {
 position: absolute;
 height: 60%;
 width: 100px;
 top: 25%;
 left: 0;
 pointer-events: none;
 opacity: 0.5;
}

.input:focus ~ .highlight {
 animation: inputHighlighter 0.3s ease;
}

@keyframes inputHighlighter {
 from {
  background: #000;
 }

 to {
  width: 0;
  background: transparent;
 }
}


/* Estilos del Selection */
.MuiOutlinedInput-root {
  width: 100%;
  padding: 0.5rem;
}

em {
  font-size: 1rem;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,Gotham, sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    color: gray;
}

.MuiChip-root.MuiChip-root:hover,
.MuiChip-root.Mui-focusVisible
 {
   background-color: #1de9b6
}

.item-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1rem;
  /* box-shadow: 2px 2px 10px #ccc; */
  margin-bottom: 0.5rem;
  transition: background-color 1s ease-out;
  background: #fff;
}

.selected-item-container {
  border: 5px solid #1de9b6;
  /* box-shadow: 0px 0px 3px 0px #3c83ca; */
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background: #ffffffcd;
  transition: background-color 1s ease-out;
}
.disabled {
  opacity: 0.4;
}

.MuiBadge-root {
   width: 100%;
}

.MuiSelect-select.MuiInputBase-input {
   padding: 0;
}

.MuiBadge-badge {
  /* background-color: #8a4fff;
  color: #fff; */
    background-color: #1de9b6;
  color: #000;
}

/* .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #1de9b6;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #1de9b6;
}

.MuiSwitch-thumb {
  color: #1de9b6;
} */

.MuiChip-label {
  color: #fff;
}

.MuiChip-filled {
  color: #fff;
}