.ticketsContainer {
  background-color: #fff;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  padding: 1rem;
  height: 100%;
}

.ticketsHeader {
  margin: 0.25rem;
}

.ticketsTitle {
  font-size: 1.1rem;
  font-weight: 700;
}

.ticketsList {
  max-height: 100%;
}

.ticketsListItem {
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 2px 2px 10px #ccc;
  cursor: pointer;
}

.ticketsListItemContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.ticketsListItemContentInner {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.ticketsListItemState {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.ticketsListItemText {
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
}

.ticketsListItemIcon {
  min-width: 0;
}

.title {
  font-weight: 900;
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000;
  letter-spacing: 0.1rem;
}