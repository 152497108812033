.pageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  height: 100%;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoSpacing {
  margin: 0.5rem;
}

.logoImg {
  width: 3.5rem;
  height: 3.5rem;
  justify-items: center;
}

.logoSpacingSmall {
  margin: 0.1rem;
}

.businessContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.businessTitle {
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 0;
  color: white;
}

.businessTitleInner {
  font-weight: 900;
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: white;
}

.businessTitleText {
  color: white;
}

.buttonContainer {
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  padding: 1rem 3rem;
}

.scanButton {
    width: 100%;
    font-size: 1rem;
    font-weight: 900;
    text-transform: none;
    letter-spacing: 0.1rem;
}

.LoadingContainer {
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 1rem;
}

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
  letter-spacing: 0.1rem;
  text-align: center;
}
