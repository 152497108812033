.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiChip-root.MuiChip-root:hover,
.MuiChip-root.Mui-focusVisible {
  background-color: #1de9b6;
}

.itemContainer {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0.5rem;
  /* box-shadow: 2px 2px 10px #ccc; */
  margin-bottom: 0.5rem;
  transition: background-color 1s ease-out;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.itemContainerDisabled {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0.5rem;
  opacity: 0.4;
  margin-bottom: 0.5rem;
  transition: background-color 1s ease-out;
  background: #fff;
}

.selectedItemContainer {
  border: 3px solid #1de9b6;
  /* box-shadow: 0px 0px 3px 0px #3c83ca; */
  border-radius: 10px;
  padding: 0.5rem;
  opacity: 0.7;
  margin-bottom: 0.5rem;
  background: #fff;
  transition: background-color 1s ease-out;
}
.disabled {
  opacity: 0.4;
}

.MuiBadge-root {
  width: 100%;
}

.MuiSelect-select.MuiInputBase-input {
  padding: 0;
}

.MuiBadge-badge {
  background-color: #1de9b6;
  color: #000;
}

/* .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #1de9b6;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #1de9b6;
}

.MuiSwitch-thumb {
  color: #1de9b6;
} */

.MuiChip-label {
  color: #fff;
}

.MuiChip-filled {
  color: #fff;
}

.rowItemsTable {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.itemLateral {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.itemMiddle {
  flex-basis: 20%;
  flex-grow: 2;
  margin: 0.5rem;
  font-weight: 700;
}

.fullWidth {
  width: 100%;
}

.dividerS {
  margin: 0.25rem;
}

.dividerM {
  margin: 1rem;
}

.disabled {
  opacity: 0.4;
}
