.dividerXXXL {
  margin: 5rem;
}

.tableContainer {
  border: 1px solid #c4c4c4;
  border-radius: 1rem;
  padding: 0px 10px;
  background: #fff;
}

.totalContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  border-bottom: 1px solid #c4c4c4;
}

.totalText {
  font-weight: 600;
  color: gray;
}

.totalNumber {
  font-weight: 600;
  letter-spacing: 0.1rem;
}
