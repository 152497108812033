/* estilos del styles general */
.ScreenContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

.dividerM {
  margin: 0.5rem;
}

.dividerXXXL {
  margin: 7rem;
}

.topButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.metricsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
  background-color: #00000085;
  border-radius: 5px;
}

.metricName {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
}

.metricValue {
    color: #fff;
    font-size: 1rem;
    font-weight: 900;
    margin-top: 0;
}

.ticketDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ticketStateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.whiteText {
  color: #fff;
}

.ticketTotalsContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.5rem;
}

.ticketTotalPayed {
  color: #fff;
  margin: 0;
  text-align: start;
}

.ticketTotal {
  color: #c4c4c4;
}

.shareContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25;
}

.QRContainer {
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
}

.QR {
  border-radius: 10px;
}

.ticketsContainer {
  background-color: #fff;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  padding: 1rem;
  height: 100%;
}

.ticketsList {
  max-height: 100%;
}

.ticketsListItem {
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 2px 2px 10px #ccc;
  cursor: pointer;
}

.ticketsListItemContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.ticketsListItemContentInner {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ticketsListItemState {
  font-size: 1.25rem;
  font-weight: 700;
}

.ticketsListItemText {
  font-size: 1rem;
  font-weight: 400;
}

.ticketsListItemIcon {
  min-width: 0;
}

.ticketsHeader {
  margin: 0.25rem;
}

.ticketsTitle {
  font-size: 1.1rem;
  font-weight: 700;
}

.logoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoSpacing {
  margin: 0.5rem;
}

.logoImg {
  width: 3.5rem;
  height: 3.5rem;
  justify-items: center;
}

.buttonContainer {
  margin: 0.5rem;
  width:100%;
  display: flex;
  justify-content: center;
}

.title {
  font-weight: 900;
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000;
  letter-spacing: 0.1rem;
}

.ticketsListItemContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.ticketsListItemContentInner {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.ticketsListItemState {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.ticketsListItemText {
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
}