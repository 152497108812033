.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
}