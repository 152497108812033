/* estilos del styles general */

.ScreenContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 0.1rem;
}

.logoImage {
  width: 3.5rem;
  height: 3.5rem;
}

.marginS {
  width: 100%;
  margin: 0.5rem;
}

.marginM {
  width: 100%;
  margin: 1rem;
}

.alertContainer {
  width: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.loginFieldsContainer {
  margin: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

h1 {
  color: #fff;
}

.LogInContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.InfoText {
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: white;
}

.dividerM {
  margin: 0.5rem;
}

.dividerL {
  margin: 1rem;
}

.logInLink {
  color: #fff;
  font-weight: 700;
  text-decoration: underline;
}

.forgotPassLink {
  color: #fff;
  font-weight: 500;
  text-decoration: underline;
}


.titleContainer {
  padding: 10px 0px;
}

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
  letter-spacing: 0.1rem;
}

.selectionTitle {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #fff;
}

.selectionSubtitle {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #ffffffd1;
  letter-spacing: 0.05rem;
  line-height: 1.4rem;
}

.LoadingContainer {
  width: 100%;
  text-align: center;
  padding: 1rem;
}