
.metricsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
  background-color: #00000085;
  border-radius: 5px;
}

.metricName {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
}

.metricValue {
    color: #fff;
    font-size: 1rem;
    font-weight: 900;
    margin-top: 0;
}

.ticketDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}