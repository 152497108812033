/* estilos del styles general */

.ScreenContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 0.1rem;
}

.logoImage {
  width: 3.5rem;
  height: 3.5rem;
}

.marginS {
  width: 100%;
  margin: 0.5rem;
}

.marginM {
  width: 100%;
  margin: 1rem;
}

.alertContainer {
  width: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.loginFieldsContainer {
  margin: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

h1 {
  color: #fff;
}

.disclaimer {
  font-size: 0.75rem;
  color: #ccc;
  text-align: center;
}

.disclaimerLink {
  color: #fff;
  text-decoration: underline;
}

.InfoContainer {
  width: 100%;
}

.LogInContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.InfoText {
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: white;
}

.dividerM {
  margin: 0.5rem;
}

.dividerL {
  margin: 1rem;
}

.logInLink {
  color: #fff;
  font-weight: 700;
  text-decoration: underline;
}