/* estilos del styles general */

.ScreenContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
}

.dividerXS {
  margin: 0.1rem;
}

.dividerS {
  margin: 0.25rem;
  width: 100%;
}

.dividerM {
  margin: 0.4rem;
}

.dividerXL {
  margin: 1rem;
}

.dividerXXXL {
  margin: 7rem;
}

.GreetingContainer {
  width: 100%;
  text-align: left;
}

.Greeting {
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: white;
}

.InfoContainer {
  width: 100%;
}

.LoadingContainer {
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 1rem;
}

.InfoText {
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: white;
}

.SwitchContainer {
  width: 100%;
  display: flex;
}

.SwitchLabel {
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  color: white;
  background-color: #00000085;
  border-radius: 5px;
}

.ReadyContainer {
  display: flex;
  flex-direction: column;
  background-color: #00000085;
  border-radius: 5px;
  width: 100%;
}

.ReadyContent {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.ReadyElementsContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.CircularProgressContainer {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.SelectionFooter {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem 1rem 0 0;
  background: #fff;
  border-top: 1px solid #c4c4c4;
}

.SelectionCountContainer {
  display: flex;
  justify-content: space-between;
}

.SelectionCount {
  margin: 0;
}

.DetailsButton {
  margin-left: 1rem;
}

.SelectionDetailsContainer {
  width: 100%;
}

.SelectionDetailsRow {
  display: flex;
  flex-direction: row;
}

.LateralItemText {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.itemMiddle {
  flex-basis: 20%;
  flex-grow: 2;
  margin: 0.5rem;
  font-weight: 700;
}

.MiddleItemText {
  margin: 0;
  flex: 1;
  padding-left: 1rem;
}

.NextButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.NextButton {
  margin-top: 1rem;
}

.MarginBottom {
  margin-bottom: 3rem;
}

.FooterContainer {
  width: 100%;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.contentBox {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.transition {
  transition: transform 0.3s ease;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem
}

.titleContainer {
  padding: 10px 0px;
}

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
  letter-spacing: 0.1rem;
}

.selectionTitle {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #fff;
}

.selectionSubtitle {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #ffffffd1;
  letter-spacing: 0.05rem;
  line-height: 1.4rem;
}

.topButtonsBox {
  display: flex;
  flex-direction: row;
      justify-content: center;
  transition: transform 0.3s ease;
}