/* estilos del styles general */

.ScreenContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
}

.titleContainer {
  width: 100%;
  margin-top: 1rem;
}

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
  letter-spacing: 0.225em;
  text-transform: uppercase;
}

.titleBig {
  font-weight: 900;
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: white;
}

.errorMessage {
  margin-bottom: 2rem;
  display: flex;
}

.formContainer {
  text-align: left;
  width: 100%;
  max-width: 400px;
}

.formLabel {
  font-weight: 400;
  width: 100%;
  font-size: 1.25rem;
  color: white;
}

/* Estilos del Welcome */
.group {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.input {
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 10px 10px 5px;
  display: block;
  /* width: 200px; */
  border: none;
  /* border-bottom: 1px solid #fff; */
  background: transparent;
  width: 75%;
  color: #fff;
}

.input:focus {
  outline: none;
}

.inputLabel {
  color: #fff;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: cursor;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.input:focus ~ label,
.input:valid ~ label {
  top: -20px;
  font-size: 0.75rem;
  color: #fff;
}

.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #1de9b6;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

.input:focus ~ .bar:before,
.input:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}

@keyframes inputHighlighter {
  from {
    background: #000;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.buttonContainer {
  margin-top: 1rem;
}

.button {
  width: 100%;
}

.disclaimer {
  font-size: 0.75rem;
  color: #ccc;
  text-align: center;
}

.disclaimerLink {
  color: #fff;
  text-decoration: underline;
}

.LoadingContainer {
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 1rem;
}