.DetailsButton {
  margin-left: 1rem;
}

.SelectionDetailsContainer {
  width: 100%;
}

.SelectionDetailsRow {
  display: flex;
  flex-direction: row;
}

.LateralItemText {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.itemMiddle {
  flex-basis: 20%;
  flex-grow: 2;
  margin: 0.5rem;
  font-weight: 700;
}

.MiddleItemText {
  margin: 0;
  flex: 1;
  padding-left: 1rem;
}

.SelectionFooter {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem 1rem 0 0;
  background: #fff;
  border-top: 1px solid #c4c4c4;
}

.FooterContainer {
  width: 100%;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.SelectionFooter {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem 1rem 0 0;
  background: #fff;
  border-top: 1px solid #c4c4c4;
}

.SelectionCountContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.SelectionCount {
  margin: 0;
}

.DetailsButton {
  margin-left: 1rem;
}

.SelectionDetailsContainer {
  width: 100%;
}

.SelectionDetailsRow {
  display: flex;
  flex-direction: row;
}

.LateralItemText {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.itemMiddle {
  flex-basis: 20%;
  flex-grow: 2;
  margin: 0.5rem;
  font-weight: 700;
}

.MiddleItemText {
  margin: 0;
  flex: 1;
  padding-left: 1rem;
}

.NextButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.NextButton {
  margin-top: 1rem;
}

.MarginBottom {
  margin-bottom: 3rem;
}

.FooterContainer {
  width: 100%;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.selectionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectionHeaderText {
  letter-spacing: 0.05rem;
}