.mainContainer {
  display: flex;
  flex-direction: column;
}

/* Landing */
body.landing #page-wrapper {
  background-image: url("./images/restaurant-1.jpg");
  background-size: cover;
  background-position: center;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

body.landing #footer {
  background-image: url("./images/restaurant-1.jpg");
  /* background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%); */
}

body.is-mobile.landing #page-wrapper {
  background: none;
}

body.is-mobile.landing #banner,
body.is-mobile.landing .wrapper.style4 {
  background-image: url("./images/restaurant-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

body.is-mobile.landing #footer {
  background-image: url("./images/restaurant-1.jpg");
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Negro con 50% de opacidad */
  z-index: 1; /* Coloca la capa encima del banner */
}

/* Banner */

	body.isPreload #banner h2 {
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		opacity: 1;
	}

		body.isPreload #banner h2:before, body.isPreload #banner h2:after {
			width: 100%;
		}

	body.isPreload #banner .more {
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		opacity: 1;
	}

	body.isPreload #banner:after {
		opacity: 0;
	}