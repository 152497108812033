.QRContainer {
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
}

.QR {
  border-radius: 10px;
}

.logoImg {
  width: 3.5rem;
  height: 3.5rem;
  justify-items: center;
}

.logoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dividerXS {
  margin: 0.25rem
}

.dividerS {
  margin: 0.5rem
}

.buttonContainer {
  margin: 0.5rem;
  width:100%;
  display: flex;
  justify-content: center;
}