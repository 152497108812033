.notchedOutline {
  border-color: #ffffff80;
}

.notchedOutline:hover {
  border-color: #ffffff80;
}

.notchedOutline.Mui-focused {
  border-color: #1de9b6;
}

.marginM {
  margin: 0.75rem;
}
