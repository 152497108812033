.mainContainer {
  background: #000;
  height: 100%;
  width: 100%;
}

.loadingContainer {
  height: 100%;
  position: relative;
}

.loadingText {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 2rem;
  opacity: 0.6;
  color: #fff
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  width: 100%;
  top: 3%;
  /* margin-left: 5%; */
  opacity: 0.6;
  z-index: 10;
}

.marginTop {
  margin-top: 0.5rem;
}

.dividerS {
  margin: 0.5rem
}

.topElementsContainer {
  padding: 1rem
}

.scanningText {
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: rgb(7 114 220);
  margin-bottom: 0.5rem;
}