/* estilos del styles general */

.ScreenContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
}

.screenTitle {
  margin: 0;
  width: 100%;
  text-align: left;
  color: #fff;
}

.screenDescription {
  margin-bottom: 0;
  color: #fff;
}

.navContainer {
  width: 100%;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  background: #fff;
  z-index: 10;
  border-top: 1px solid #c4c4c4;
}

.navButtonsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  padding: 1rem;
  gap: 2rem;
}

.editButtonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5rem;
  gap: 1rem;
}

.dividerXS {
  margin: 0.1rem;
}

.dividerS {
  margin: 0.25rem;
  width: 100%;
}

.dividerM {
  margin: 0.5rem;
}

.dividerXL {
  margin: 1rem;
}

.dividerXXXL {
  margin: 5rem;
}

.pageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  height: 100%;
  background: linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GreetingContainer {
  width: 100%;
  text-align: left;
}

.titleContainer {
  padding: 10px 20px;
}

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
  letter-spacing: 0.1rem;
}

.selectionTitle {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #fff;
}

.selectionSubtitle {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #ffffffd1;
  letter-spacing: 0.05rem;
  line-height: 1.4rem;
}

.LoadingContainer {
  width: 100%;
  text-align: center;
  padding: 1rem;
}