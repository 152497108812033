
@font-face {
  font-family: 'Gotham';
  src: local('Gotham') url('/src/fonts/GothamMedium_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham') url('/src/fonts/GothamLight.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham') url('/src/fonts/GothamBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,Gotham, sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.625rem;
  text-align: center
}

h2 {
  font-weight: bold;
  font-size: 1rem;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
}

ul {
  padding-left: 15px
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem
}

a {
  text-decoration: none;
}

/* Reduce space for cells in a table */
.MuiTableCell-root {
  padding: 0.75rem
}

.MuiInputBase-input {
  text-align: center;
}
